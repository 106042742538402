@import "../helpers/helpers.scss";

.basket {
  &-items-wrapper {
    .total-block {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0;

      .grand-total-row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $gray400;

        h2,
        h3 {
          margin-bottom: 20px;
        }

        h2 {
          font-size: 18px;
          font-weight: bold;
          margin-right: 6em;
        }

        h3 {
          line-height: 1;
          display: inline-flex;
          align-items: center;
          font-size: 26px;
          font-weight: 700;
        }
      }
    }
  }
}

.basket-list-item {
  &_right__bottom__left,
  &_right__top,
  &_inner,
  &_right__bottom,
  &_wrapper {
    @include flex-def;
  }

  &_wrapper {
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid $gray400;

    &:nth-of-type(1) {
      border-top: 1px solid $gray400;
    }
  }

  &_left {
    width: 25%;
    padding-right: 10px;

    img {
      margin: 0 auto;
      min-width: 50px;
      max-height: 200px;
    }
  }

  &_right {
    width: 75%;

    .update-cart-button {
      width: 60px;
      height: 20px;
    }

    .update-cart-button,
    .cart-remove-icon {
      cursor: pointer;
      max-width: 50px;
      padding: 10px;
      border-radius: 10px;
    }

    .cart-remove-icon {
      max-width: 30px;
      position: absolute;
      top: 0;
      right: 5px;
      border: 2px solid $brand;
      border-radius: 50%;
      padding: 5px;
      transition: all 0.3s ease-in-out;

      &:hover {
        filter: contrast(.1);
      }
    }

    .update-cart-button {
      @include gradientBtn;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: unset;

      .update-cart-item {
        transition: .5s;

        &.refreshed {
          transform: rotate(90deg);
        }
      }

      img {
        filter: brightness(0) invert(1);
        width: 75%;
      }
    }
  }

  &_right__top {
    margin-bottom: 15px;
  }

  &_right__top &_inner {
    margin-bottom: 0;
    position: relative;
    padding-right: 20px;
    width: auto;
    align-items: center;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      border-right: 0;
      padding-right: 0;
    }
  }

  &_right__bottom {
    align-items: flex-end;
    float: right;
    margin-top: 45px;

    .rotation_code_basket {
      display: flex;
      flex-flow: column-reverse;
      line-height: 40px;
      padding: 0 20px 0 0
    }
  }

  &_right__bottom__right {
    @include flex-col;
    margin-top: 20px;

    .update-cart-btn i {
      transition: all 0.3s ease-in-out;
    }

    .refreshed i {
      transform: rotate(95deg);
    }
  }

  &_right__bottom &_inner {
    width: auto;
  }

  &-title-link,
  &-title-link-wrapper {
    position: relative;
  }

  &-title-link-wrapper {
    margin-bottom: 12px;
    height: 25px;
    display: flex;
    align-items: center;
  }

  &-title {
    text-align: left;
    transition: color $trans;
    margin-bottom: 0;
  }

  &-title-link {
    display: inline-block;
    @include m-max($sm) {
      max-width: 85%;
    }

    &::before {
      position: absolute;
      bottom: -3px;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: $gray400;
      transition: all $trans;
    }

    &::after {
      position: absolute;
      bottom: -3px;
      left: 0;
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: $gray400;
      transition: all $trans;
    }

    &:hover {
      text-decoration: none;

      &::after {
        width: 100%;
      }
    }
  }

  &-label {
    margin-right: 5px;

    &.cases,
    &.bottles {
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  &-input-label {
    font-size: 14px;
    margin-bottom: 10px;
  }

  &_inner {
    margin-bottom: 15px;
    width: 100%;

    * {
      font-family: $font;
    }

    h6 {
      font-size: $fszSm;
    }

    p {
      font-size: $defsz;
    }

    &.input input {
      width: 40%;
      padding: 5px;
      text-align: center;

      &[type=number] {
        width: 100%;

        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          -moz-appearance: textfield;
        }
      }
    }
  }

  &_inner.case-nett {
    align-items: flex-end;
    margin-bottom: 10px;
    margin-right: 10px;
    justify-content: flex-end;

    h6 {
      margin-bottom: 0;
      align-items: center;
    }

    p {
      line-height: 1;
      display: inline-flex;
      align-items: center;
      margin-bottom: -1px;
      font-size: 26px;
      font-weight: $bold500;
    }
  }

  &_inner.input {
    @include flex-col;
    align-items: center;
    margin-bottom: 0;
    max-width: 90px;
  }

  &_inner.input &-input-label {
    font-weight: $bold600;
  }

  &_inner.input:last-of-type {
    margin-bottom: 0;
  }

  &_inner.update-cart {
    margin-bottom: 0;
    width: 60px;

    i {
      margin-left: 0;
    }
  }
}

@include m-max($sm) {
  .page.basket .basket-items--cart-control {
    button {
      font-size: $fszSm;
    }
  }

  .basket-list-item {
    &_right__top &-item_inner {
      padding-top: 10px;
      padding-bottom: 10px;

      h6 {
        margin-bottom: 0;
      }
    }

    &_right__bottom {
      margin-top: 25px;
      .rotation_code_basket {
        display: block;
        width:100%;
        text-align: center;
      }
    }

    &_right__bottom__right,
    &_right__bottom__left {
      width: 100%;

    }

    &_inner p {
      font-size: 12px;
    }

    &_right__bottom__right {
      @include flex-col-rev;
      margin-bottom: 20px;
    }

    &-title {
      margin-bottom: 0px;
      font-size: $defsz;

      &-link:before {
        bottom: -3;
      }
    }

    &_inner.input {
      width: calc(50% - 8px);
      max-width: 100%;
      padding-right: 10px;
    }

    &_inner.input:last-of-type {
      padding-right: 0;
    }

    &_inner.case-nett {
      flex: auto;
      order: 3;
      margin-top: 15px;
      align-items: flex-end;
      margin-bottom: 0;

      h6 {
        margin-bottom: 0;
      }
    }

    &_inner.update-cart {
      margin-top: 15px;
      width: 100%;
    }

    &_right {
      width: 80%;

      &__top {
        margin-top: 10px;
      }

      .cart-remove-icon {
        max-width: 25px;
      }
    }

    &_left {
      width: 20%;

      img {
        min-width: unset;
        max-height: 100px;
        object-fit: contain;
      }
    }
  }
}

@include m-min($sm) {
  .basket-list-item {
    &_wrapper {
      align-items: center;
    }

    &_left {
      width: 20%;
    }

    &_right {
      width: 80%;
    }

    &_right__top {
      margin-bottom: 10px;
    }

    &_right__top &_inner {
      margin-bottom: 0;
      align-items: center;
      position: relative;
    }

    &-title-link {
      margin-bottom: 20px;
    }

    &_inner {
      margin-bottom: 15px;
      width: 100%;

      h6 {
        font-size: 16px;
      }

      p {
        font-size: 18px;
      }

      &.input input {
        max-width: 100%;
      }
    }

    &_inner.case-nett {
      flex: 1;
    }

    &_right__bottom &_inner.input {
      margin-right: 15px;

      &:first-of-type {
        margin-right: 0;
      }
    }
  }
}

@include m-min($md) {
  .basket-list-item {
    width: 100%;

    &:last-of-type {
      border-bottom: 0;
    }

    &_inner.case-nett {
      flex: auto;
      flex-direction: row;
      align-items: flex-end;

      h6 {
        margin-bottom: 0;
      }
    }
  }
}

@include m-min($lg) {
  .basket-list-item {
    &_right {
      @include flex-def;
    }

    &-title-link-wrapper {
      width: 100%;
    }

    &-title-link {
      margin-bottom: 30px;
    }

    &_right__top,
    &_right__bottom {
      align-items: center;
      align-content: center;
    }

    &_right__top {
      margin-bottom: 0;
    }

    &_right__bottom {
      align-items: flex-end;
      margin-top: 0;
      padding-left: 30px;
      margin-left: auto;
    }

    &_left img {
      max-height: 150px;
    }

    &_inner.update-cart {
      width: 60px;
    }
  }
}

.update-cart-btn {
  svg {
    padding-left: 5px;
  }
}


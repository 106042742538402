@import "../helpers/helpers.scss";

.basket-list-popup {
  position: fixed;
  left: auto;
  z-index: 999;
  width: 100%;
  opacity: 0;
  background: #ffffff;
  padding: 15px;
  box-shadow: 0 3px 6px #00000029;
  max-width: 500px;
  visibility: hidden;
  max-height: calc(100% - 90px);
  overflow-x: hidden;
  overflow-y: auto;
  right: 90px;

  &::-webkit-scrollbar {
     width:10px;
    background-color: $gray100;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray400;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  &::after {
    position: absolute;
    top: 0;
    right: -100%;
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: $brand;
    transition: all .5s linear .1s;
  }

  &.opened {
    transition: all $trans;
    opacity: 1;
    visibility: visible;

    &::after {
      width: 100%;
      right: 0;
    }
  }
}

@include m-min($sm) {
  .basket-list-popup {
    top: 90px;
    right: calc((100% - 720px)/2);
  }
}

@include m-min($md) {
  .basket-list-popup {
    right: calc((100% - 960px)/2);
  }
}

@include m-max($sm) {
  .basket-list-popup {
    min-width: 100%;
    height: calc(100% - 80px);
    max-height: calc(100% - 80px);
    overflow-x: hidden;
    right: 0;
    top: 90px;

    .busket__wrapper {
      margin-bottom: 20px;
    }

  }
}

@include m-max($xs) {
  .basket-list-popup {
    top: 80px;
  }
}

@include m-max(320) {
   .basket-list-popup {
     top: 115px;
   }
 }

@include m-min($lg) {
  .basket-list-popup {
    display: none;
    top: 90px;
  }
}
@import "../helpers/helpers.scss";

.header-logo {
  max-width: 220px;

  &-img {
    height: 60px;
  }
}

@include m-max($xs) {
  .header-logo-img {
    max-width: 155px;
    object-fit: contain;
    margin-top: -10px;
  }
}

@include m-max($lg) {
  .header-logo {
    order: 2;
  }
}

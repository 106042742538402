@import "../helpers/helpers.scss";

.product-list-filter {
  &.hide {
    display: none;
  }
}

.pagination {
  display: flex;
  align-items: center;
  position: relative;
  min-width: 90px;
  flex-wrap: wrap;

  li {
    cursor: pointer;

    a {
      display: block;
      height: 100%;
      padding: 0 7px;
      width: 100%;
      color: $gray400;
      font-weight: $normalW;
      font-family: $font;
      margin-bottom: -2px;
    }

    &.active {
      align-items: center;
      justify-content: center;

      a {
        color: $brand;
        font-weight: $bold700;
      }
    }

    &:hover a {
      color: $brand;
    }
  }

  * {
    font-size: 16px;
    color: $brand;
    font-weight: $bold600;
  }

  .next {
    @include right-arrow;
    background-position: center left 55%;
  }

  .previous {
    @include left-arrow;
    background-position: center right 55%;
  }

  .next,
  .previous {
    position: relative;
    height: 30px;
    width: 30px;
    display: inline-block;
    text-align: center;
    padding: 2px;
    border: 2px solid $brand;
    border-radius: 50%;
    text-indent: -9999px;
    background-size: 55%;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all $trans;

    &:hover {
      border: 2px solid $gray400;
    }
  }
}

@include m-max($lg) {
  .pagination li {
    display: none;

    &.active {
      display: inline-flex;
    }
  }
}

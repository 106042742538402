@import "../helpers/helpers.scss";

.total {
    &-col {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &-col__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        border-bottom: 1px solid $gray400;
        width: 320px;

        h3 {
            margin: unset;
            font-size: 26px;
        }

        h4 {
            margin: unset;
            display: flex;
            justify-content: flex-end;
            padding-left: 20px;
        }

        &:last-of-type {
            border-bottom: none;
            h3 {
                font-weight: $bold600;
            }
            h4 {
                padding-left: 0;
                font-weight: $bold700;
            }
        }
    }
}

@include m-max($xs) {
    .total {
        &-block {
            width: 100%;
        }

        &-col__row {
            width: 90%;

            h4 {
                justify-content: unset;
            }
        }
    }
}
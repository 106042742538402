@import "../helpers/helpers.scss";

.footer {
  background: $gray50;
  box-shadow: 0 -1px 5px $shadow100;
  padding: 20px 0;

  &-logo_right {
    padding-left: 20px;
  }
  &-logo_left, &-logo_right {
    width: 150px;
  }
  * {
    font-size: $defsz;
    color: $brand;
    font-family: $font;
  }

  &-bottom_inner_top,
  &-bottom_inner_bottom,
  &-top {
    @include flex-def;
    justify-content: center;
  }

  &-top {
    margin-bottom: 40px;
  }

  &-bottom_inner_top {
    margin-bottom: 10px;

    p {
      color: $gray400;
    }
  }

  &-logo,
  &-logo:hover {
    img,
    &::before {
      transition: opacity $trans;
    }
  }

  &-logo {
    position: relative;

    img {
      opacity: 1;
    }

    &::before {
      content: '';
      display: block;
      background: url(../../assets/open-imagination-purple.png) no-repeat;
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0;
    }

    &_left::before {
      background: url(../../assets/open-imagination-purple.png) no-repeat;
      background-size: 150px auto;
    }

    &_right::before {
      background: url(../../assets/Vintner-logo-purple.png) no-repeat;
      background-size: 130px auto;
    }

    &:hover {
      img {
        opacity: 0;
      }

      &::before {
        opacity: 1;
      }
    }
  }

  &-link {
    display: inline-block;
  }

  &-privacy-link {
    padding-right: 10px;
    position: relative;

    &::after {
      content: '';
      display: block;
      height: 100%;
      width: 1px;
      position: absolute;
      top: 0;
      right: 0;
      background: $gray400;
      box-shadow: 0 1px 1px $gray400;
    }
  }

  &-terms-conditions-link {
    padding-left: 10px;
  }
}

@include m-min($sm) {
  .footer {
    padding: 50px 0;

    * {
      font-size: 18px;
    }

    &-logo_left {
      padding-right: 30px;
      margin-bottom:0;
      width:180px;
      &::before {
        background: url(../../assets/open-imagination-purple.png) no-repeat;
        background-size: 150px auto;
      }
      &::after {
        content: '';
        display: block;
        height: 100%;
        width: 1px;
        position: absolute;
        top: 0;
        right: 0;
        background: $gray400;
        box-shadow: 0 1px 1px $gray400;
      }
    }

    &-logo_right {
      width:180px;
      padding-left: 30px;
    }
    &_right::before {
      background-size: 150px auto;
    }
  }
}
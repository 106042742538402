@import '../helpers/helpers.scss';

.terms-conditions,
.privacy {
  &-page {
    display: flex;
    flex-direction: column;
    background: transparent;
    box-shadow: none;
    padding: 5px;
    justify-content: center;

    h2 {
      font-size: 25px;
      margin: 30px 0 10px;
      color: $gray400;
    }
    p {
      font-size: $defsz;
      line-height: 20px;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
    }
  }

  &-logo {
    margin-bottom: 30px;
  }

  &-page-title {
    font-size: 30px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    width: 100%;
    border-bottom: 1px solid $gray400;
  }

  &-page-text {
    font-family: $font;
    font-size: $defsz;
    display: block;
    max-width: 80%;
    text-align: center;
    line-height: 1.3;
  }
}

@import "../helpers/helpers.scss";

.react-select-custom .react-select {
  &__control {
    border-color: $gray400;
    box-shadow: none;

    &:hover {
      border-color: $brand;
    }
  }

  &__value-container {
    display: flex;
    align-items: center;
    padding: 0;
    line-height: 1;
    justify-content: center;
    margin-bottom: -1px;
    cursor: pointer;
    font-family: $font;

    input {
      height: 0;
      width: 0;
    }
  }

  &__single-value {
    color: $gray400;
    position: static;
    margin: 0;
    transform: none;
    max-width: 100%;
    padding: 0 8px;
  }

  &__single-value,
  &__option {
    font-family: $font;
  }

  &__menu {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__option {
    background: $white;
  }

  &__option--is-focused,
  &__option--is-selected {
    background: $brand;
    color: $white;
    cursor: pointer;
  }

  &__indicator {
    transition: all $trans;
    cursor: pointer;
  }

  &__control--menu-is-open .react-select__indicator {
    transform: rotate(180deg);
  }
}
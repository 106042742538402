@import "../helpers/helpers.scss";

.page.reports-page {
  input[type="text"] {
    padding: 0 10px;
    min-height: 30px;
    height: 38px;
  }

  @include flex-def;
  font-family: $font;
  gap: 20px;
  align-items: revert;

  @include m-max($md) {
    @include flex-col;
    gap: revert;
  }

  .page-title {
    margin-bottom: 30px;
    width: 100%;
  }

  .totals {
    .box {
      h3 {
        margin-bottom: 15px;
      }

      p {
        font-size: 32px;
        text-align: center;
      }
    }
  }

  .content-half {
    button {
      margin-bottom: 30px;
    }
  }

  input.error {
    border: 1px solid $error;
  }

  .MuiToolbar-regular {
    padding: 0;
  }

  .MuiIconButton-root {
    width: 38px;
    margin-left: 20px;
  }

  .Row-row {
    .MuiIconButton-root {
      width: auto;
    }
  }

  .MuiInputBase-input {
    width: 250px;
  }

  .MuiPaper-root {
    box-shadow: none;
  }
}


.MuiTableHead-root {

  tr.MuiTableRow-head:nth-child(even) {
    background: white !important;

    th {
      visibility: hidden;
    }

    th:nth-child(5) {
      visibility: visible;
      border:0;

      div {
        margin: 0;
        width: 150px;
      }

      input {
        width: auto;
        background: none;
        margin: 0;
        padding: 0;

        &:hover, &:focus, &:active {
          border: 0;
          outline: none;
        }
      }
    }
  }
}

.MuiInputBase-input {
  background: none;
}






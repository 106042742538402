@import "../helpers/helpers.scss";
.page.orders-page {
  @include flex-col;
  align-items: center;
  font-family: $font;

  .page-title {
    margin-bottom: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray400;
    width: 100%;
  }

  input.error {
    border: 1px solid $error;
  }
}

.orders {
  &__field.datepicker {
    .react-datepicker__day--selected {
      background-color: $brand;
    }
    .react-datepicker__navigation {
      min-height: unset;
      width: 28px;
      height: 28px;

      .react-datepicker__navigation-icon--previous {
        right: unset;
      }
      .react-datepicker__navigation-icon--next {
        left: 1px;
      }
    }
  }
  &__search {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
  }

  &__field,
  &__submit_wrapper {
    margin-top: 15px;
  }

  &__field {
    margin-right: 10px;

    p {
      margin-bottom: 5px;
    }
  }

  &__list_col_label {
    display: none;
  }

  &__list_header {
    background-color: $brand;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__items_header_inner {
    color: $white;
  }

  &__wrapper {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  &__list_header,
  &__list_row {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid;
    grid-gap: 1px;
    padding: 20px 0;

    .orders__list_col-order-no {
      p,a {
        color: var(--customColor);
      }
    }
  }

  &__list_col {
    p {
      text-align: center;
    }
    a {
      text-decoration: underline;
    }
    &.button a {
      background-image: linear-gradient(
                      90deg, $brand 0%, $brand 35%, $brand 100%);
      cursor: pointer;
      color: $white;
      transition: all .3s ease-in-out;
      background-size: 300% 100%;
      opacity: .9;
      border-color: transparent;
      font-size: 16px;
      padding: 8px 10px 7px;
      font-weight: 600;
      text-decoration: none;
      border-radius: 4px;

      &:hover {
        background-position: 100% 0;
        opacity: 1;
      }
      &:disabled {
        background-image: linear-gradient(
                        90deg, #ddd 0%, $brand 35%, #eee 100%);
        color: $gray100;
    }
    }
  }
}

@include m-max($md) {
  .orders {
    &__list_header {
      height: 0;
      padding: 0;
      display: none;
    }

    &__search {
      flex-direction: column;
      align-items: center;
    }

    &__list_row {
      position: relative;
      grid-template-columns: repeat(1, 100%);
      padding: 10px 0;
    }

    &__list_col_label {
      display: flex;
      padding: 0 0;
    }

    &__list_col {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 50%);
      align-items: center;
      grid-gap: 1px;
      padding: 10px 0;

      p {
        text-align: right;
      }

      &.button a {
        grid-column: span 2;
        padding: 10px 0;
        text-align: center;
        width: 65%;
        margin: auto;
      }
    }

    &__wrapper {
      width: 100%;
    }

    &__list_body {
      width: 100%;
      border-top: 2px solid;
    }
    &__list_row {
      border-bottom: 2px solid;
    }
  }
}
@import '../helpers/helpers';
.busket {
  &__list_header {
    background-color: $brand;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 25px;
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid;
    grid-gap: 1px;
    padding: 20px 10px;
  }
  &__empty-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    gap: 20px;
    h3 {
      margin: unset;
    }
  }
  &__return-button {
    max-width: 200px;
    a{
      font-size: $fszSm;
      color: $white;
    }
  }
  &__items_header_inner {
    color: $white;
  }

  &-list {
    position: relative;

    &-link {
      @include flex-col;
      align-items: center;
    }

    &-icon {
      height: 25px;
      width: 25px;
      margin-right: 10px;
      transition: all $trans;
    }

    &-count {
      @include flex-def;
      @include flex-center;
      position: absolute;
      top: -8px;
      right: -8px;
      height: 16px;
      width: 16px;
      background: $gray400;
      color: $error;
      border-radius: 50%;
      overflow: hidden;
      font-size: 12px;
    }

    .total-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      font-family: $font;
    }

    &-card {
      width:100%;
    }

    &-item {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr 25px;
      align-items: center;
      justify-items: center;
      border-bottom: 1px solid;
      grid-gap: 1px;
      padding: 10px;

      &_col_label {
        display: none;
      }

      p {
        text-align: center;
      }

      h2 {
        font-size: 12px;
        margin: unset;
      }

      &-remove-icon {
        max-width: 12px;
        cursor: pointer;
      }
    }

    &-list-link {
      @include flex-col;
      align-items: flex-start;
    }
  }
}

@include m-max($sm) {
  .busket__list_header {
    display: none;
  }

  .busket-list {
    &-card {
      width: 100%;
      font-family: $font;
    }

    &-item {
      position: relative;
      grid-template-columns: repeat(1, 100%);

      &:first-of-type {
        padding-top: 10px;
      }
    }

    &-item_col {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 50%);
      align-items: center;
      grid-gap: 1px;
      padding-bottom: 10px;

      &:first-of-type {
        padding-top: 20px;
      }

      &_label {
        display: block;
      }
    }

    &-item-remove-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

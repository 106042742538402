@import '../helpers/helpers.scss';

.header {
  box-shadow: 0 3px 6px #00000029;
  padding: 15px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  background-color: $white;

  &_inner {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .nav-menu_item-logout a{
    cursor: pointer;
  }

  .nav-menu,
  &_menu_login-link,
  &_inner,
  &_menu,
  &_menu > button,
  &_inner > .nav-menu > li a  {
    display: flex;
  }

  .nav-menu,
  &_menu_login-link {
    align-items: center;
  }

  &_inner > .nav-menu {
    flex: 1;
    justify-content: center;
    padding: 0 15px;
  }

  &_menu,
  &_inner > .nav-menu {
    > button,
    > li a {
      justify-content: center;
      padding: 10px 12px;
      color: $gray400;
      font-family: $font;
      font-size: $defsz;

      &.active,
      &:hover {
        color: $brand;
        text-decoration: unset;
      }
    }
  }
}

@include m-max($lg) {
  .header {
    &_menu {
      order: 3;
    }

    .header_inner > .nav-menu {
      display: none;
    }

    &_menu,
    &_inner > .nav-menu {
      > button,
      > li a {
        padding: 10px 15px;
      }
    }
  }
}

@include m-min($lg) {
  .header {
    .logout-btn {
      max-width: 150px;
    }
  }
}

.checkout-button {
  width:auto;
  display:inline-block;
  margin-left:30px;
  margin-top:8px;
}
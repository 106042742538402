@import "../helpers/helpers.scss";

.prospects {
  padding: 0 60px 60px;
  @include m-max($md) {
    padding: 0 20px 60px;
  }

  label, button {
    display: block;
    position: relative;

    &:hover {
      z-index: 1;
    }
  }

  label {
    display: flex;
    padding-bottom: 10px;
    font-size: 14px;
    margin-top: 0;
  }

  input[req], textarea[req] {
    background: url(../../assets/asterisk-30.png) no-repeat;
    background-size: 8px 8px;
    background-position: right 5px top 5px;
    background-color: #e6e6e6;
  }

  label.preferredDelivery {
    padding-bottom: 15px;
  }

  select, input, textarea {
    margin-bottom: 30px;
  }

  select {
    padding: 10px 5px;
    border: 1px solid $gray400;
    border-radius: 5px;
    color: $gray400;
    display: block;
    width: 100%;
    background: url(../../assets/chevron.png) no-repeat;
    background-size: 16px 16px;
    background-position: right 20px top 10px;
  }

  .select-wrapper {
    background: url(../../assets/asterisk-30.png) no-repeat;
    background-size: 8px 8px;
    background-position: right 5px top 30px;
  }

  /* Customize the label (the container) */
  .checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 24px;

    &:last-child {
      margin-bottom: 30px;
    }
  }

  /* Hide the browser's default checkbox */
  .checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid $gray400;
    background-color: $white;
  }


  /* When the checkbox is checked, add a blue background */
  .checkbox input:checked ~ .checkmark {
    background-color: $brand;
    border: 1px solid $brand;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .error {
    color: $brand;
    font-size: 12px;
    line-height: 20px;
    margin-top: -30px;
    margin-bottom: 10px;
  }
}

.storage_item {
  .row {
    position: relative;
    border: 1px solid #eee;
    padding: 30px 30px 0;
    margin: 0 0 30px;
    @include m-min($md) {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 0 15px;
    }
    .remove {
      width: 30px;
      position: absolute;
      top: 0;
      right: 0;
      color: #818181;
      background: none;

      &:hover {
        color: $brand;
      }
    }

    .storage_field {
    }
    .storage_field.description {
      grid-column-start: span 5;
    }
  }
  .add {
    background:#818181;
    color:white;
    margin-bottom:60px;
    &:hover {
      background:$brand;
    }
  }
}
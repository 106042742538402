@import "../helpers/helpers.scss";

.basket-button {
  order: 1;
  display: flex;
  align-items: center;
}
.mobile-case-icon, .mobile-bottle-icon {
  height: 30px;
  padding-right:20px;
  margin-top:2px;
}
.mobile-case-total-items, .mobile-bottle-total-items {
    background: $brand;
    border-radius: 50%;
    overflow: hidden;
    width: 18px;
    height: 18px;
    position: absolute;
    top: -5px;
    right: 8px;
    color: $white;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.mobile-case-total-items {
  right:45px;
}




.mobile-basket {
  * {
    font-family: $font;
  }

  &-btn {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;



    &::before,
    img {
      transition: opacity $trans;
    }

    &.opened,
    &:hover {
 opacity: 0.9;

    }
  }

  &-icon {
    width: 60px;
    height: 44px;

  }

  &-price {
    color: $brand;
    margin-right: 7px;
  }

  &-total-items {
    //background: $brand;
    //border-radius: 50%;
    overflow: hidden;
    width: 40px;
    height: 30px;
    position: absolute;
    top: -3px;
    right: 20px;
    color: $white;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.desk {
      top: 10px;
    }
  }
}

@include m-min($sm) {
  .basket-button {
    position: relative;
  }
}

@include m-min($lg) {
  .basket-button {
    display: none;
  }
}
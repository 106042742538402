@import "../helpers/helpers";

.page.account-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .transactions__field .error {
    border: 1px solid $error;
  }

  .transaction-list .item {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
}

.orders__search-account  h2 {
  margin: 30px auto 5px auto;
}

.page.orders-page.account-page .orders__wrapper .orders__list_header,
.page.orders-page.account-page .orders__wrapper .orders__list_row {
  grid-template-columns: repeat(7, 1fr);
}

@include m-max($md) {
  .page.orders-page.account-page .orders__wrapper .orders__list_header,
  .page.orders-page.account-page .orders__wrapper .orders__list_row {
    grid-template-columns: repeat(1, 1fr);
  }
}

@include m-min($md) {
  .orders {
    &__search-account {
      width: 100%;

      h2 {
        margin: 0 auto 5px 0;
      }
    }
  }
}
@import "../helpers/helpers.scss";
.print_version {
   display: none;
}
.order-details-title {
   display: flex;
   justify-content: space-between;
   height: 30px;
   margin: 0;
   font-family: $font;

   .order-details-link {
      cursor: pointer;

      h3 {
         font-weight: 500;
         color: $brand;
      }

      &:hover {
         color: $brand-hover;
      }
   }
}

.order-details-header {
   display: flex;
   justify-content: flex-end;
   width: 100%;
   padding: 10px 0;
   border-bottom: 1px solid $gray400;

   h2 {
      margin: 0 auto 20px;
      font-weight: 500;
   }
   .print_icon {
      max-width: 40px;
      cursor: pointer;
   }
}

.order-details {
   display: grid;
   font-family: $font;
   grid-template-columns: 1fr 1fr 1fr;
   font-size: 16px;
   line-height: 24px;
   grid-template-areas:
      "orderNo orderDate customerRef"
      "deliveryAddress billingAddress ."
      "delivery-notes delivery-notes re-order"
      "table table table"
      "divider divider divider"
      "totals totals totals";
   row-gap: 20px;

   &-orderNo {
      grid-area: orderNo;
      display: flex;
      flex-direction: column;
   }

   &-orderDate {
      padding-left: 100px;
      grid-area: orderDate;
      display: flex;
      flex-direction: column;
   }

   &-customerRef {
      grid-area: customerRef;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
   }

   &.order-details-hide {
      visibility: hidden;
   }

   &.order-details-show {
      visibility: visible;
   }

   &-re-order {
      grid-area: re-order;
      max-width: 200px;
   }

   .total-block {
      grid-area: totals;
   }

   &-billingAddress {
      padding-left: 100px;
      grid-area: billingAddress;
      display: flex;
      flex-direction: column;
   }

   &-deliveryAddress {
      grid-area: deliveryAddress;
      display: flex;
      flex-direction: column;
   }

   &-delivery-notes {
      grid-area: delivery-notes;
   }

   &-divider {
      grid-area: divider;
      width: 100%;
      border-bottom: 1px solid $gray400;
   }
}

.order-details {
   margin-top: 30px;
   font-size: 16px;

   .product-list {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: $gray400;
      grid-area: table;
   }
}

.order-details-bold {
   margin-bottom: 3px;
   font-weight: bold;
}

.product-list-remark {
   font-size: 17px;
   align-self: flex-start;
   margin-bottom: 20px;
}

@media screen and (max-width: 1020px) {
   .order-details {
      &-billingAddress, &-orderDate {
         padding-left: 30px;
      }
   }
}

@include m-max($sm) {
   .order-details-title {

      .order-details-link {
         font-size: 14px;
      }

      & h3 {
         font-size: 14px;
      }
   }

   .order-details-header {
      margin: 10px;
   }

   .order-details {
      width: 100%;
      grid-template-columns: auto;
      grid-template-areas:
      "orderNo"
      "orderDate"
      "customerRef"
      "deliveryAddress"
      "billingAddress"
      "delivery-notes"
      "table"
      "divider"
      "totals";

      &-re-order {
         margin-bottom: 20px;
      }

      &-billingAddress,
      &-orderDate {
         padding: 0;
         align-items: center;
      }

      &-orderNo, &-customerRef,
      &-deliveryAddress, &-delivery-notes  {
         align-items: center;
      }
   }
}
@media print {
   .print_version {
      display: block;
   }
   .no_print {
      display: none;
   }
   .order-details-title .order-details-link {
      display: none;
   }
   .order-details-title {
      justify-content: flex-end;
   }
   .header_inner > .nav-menu {
      display: none;
   }
   .header_menu {
      display: none;
   }
   .header {
      box-shadow: unset;
   }
   .order-details {
      margin-top: 10px;
   }
   .order-details {
      row-gap: 5px;
   }
   .order-details-re-order {
      display: none;
   }
   .product-list-item_inner.update-cart {
      display: none;
   }
   .product-list-item_right {
      display: grid;
      grid-template-columns: 0.7fr 1fr;
      width: 90%;
   }
   .product-list-item_right__bottom {
      grid-column: 2;
   }
   .product-list-item-input-label {
      display: none;
   }
   .product-list-item_inner.input {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      max-width: 20%;

      p {
         align-items: flex-end;
         line-height: 20px;
         margin-left: 5px;

      }
   }
   .product-list-item_inner.case-nett p {
      margin-left: 5px;
      font-size: 17px;
   }
   .product-list-item_inner.case-nett {
      margin-bottom: 0;
   }
   .product-list-item-title-link {
      margin-bottom: unset;
   }
   .product-list-item_right__bottom {
      margin: unset;
      padding: unset;
      display: flex;
      align-items: center;
   }
   .product-list-item {
      padding: 5px 0;
   }
   .product-list-item-title-link-wrapper {
      grid-row: 1/span 2;
      align-self: center;
   }
   .product-list-item-title {
      font-size: 16px;
   }
   .product-list-item_left {
      width: 10%;

      img {
         height: 80px;
         object-fit: contain;
      }
   }
   .footer-bottom_inner_bottom {
      display: none;
   }
   .order-details.order-details-show p {
      font-size: 14px;
      line-height: 16px;
   }
   .total-col__row {
      padding: 5px 0;
   }
   .total-col__row h3 {
      font-size: 18px;
   }
   .footer {
      padding: 10px;
   }
   .content {
      padding: 10px 0;
   }
   .footer-top {
      margin-bottom: 10px;
   }
   .print_icon {
      display: none;
   }
}
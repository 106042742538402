@import '../helpers/helpers';

.mobile-hamburger-btn {
  position: relative;

  .navbar_toggler {
    padding: 0;
    background: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: box-shadow $trans;
  }
}

.navbar_toggler {
  width: 25px;
  height: 25px;
  position: relative;
  transform: rotate(0deg);
  cursor: pointer;
  min-height: auto;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $gray400;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transition: all $trans;
  }

  span:nth-child(1) {
    top: 4px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 12px;
  }

  span:nth-child(4) {
    top: 20px;
  }
}

.opened {
  .navbar_toggler span {
    background: $brand;
  }

  .navbar_toggler span:nth-child(1) {
    top: 12px;
    width: 0;
    left: 50%;
  }

  .navbar_toggler span:nth-child(2) {
    transform: rotate(45deg);
  }

  .navbar_toggler span:nth-child(3) {
    transform: rotate(-45deg);
  }

  .navbar_toggler span:nth-child(4) {
    top: 8px;
    width: 0;
    left: 50%;
  }
}

@include m-min($lg) {
  .mobile-hamburger-btn {
    display: none;
  }
}